import { Locale } from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
import { Select } from '@amzn/awsui-components-react/polaris';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';

interface LocaleSelectorProps {
  currentLocale: Locale;
  onLocaleChange: (newLocale: Locale) => void;
}

export const LocaleSelector = ({
  currentLocale,
  onLocaleChange,
}: LocaleSelectorProps) => {
  const localeOptions: OptionDefinition[] = Object.values(Locale).map(
    (locale) => ({
      value: locale,
      label: `Locale: ${locale
        .split('_')
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(' ')}`,
    })
  );

  const selectedOption =
    localeOptions.find((option) => option.value === currentLocale) ||
    localeOptions[0];

  const handleChange = (event: {
    detail: { selectedOption: OptionDefinition };
  }) => {
    onLocaleChange(event.detail.selectedOption.value as Locale);
  };

  return (
    <Select
      options={localeOptions.map((option) => ({
        ...option,
        label: option.label?.replace('Locale: ', '') || '', // Added null check and fallback
      }))}
      selectedOption={{
        ...selectedOption,
        label: `Locale: ${selectedOption.label?.replace('Locale: ', '') || ''}`, // Added null check and fallback
      }}
      onChange={handleChange}
      ariaDescribedby='language-selector'
      triggerVariant='option'
      selectedAriaLabel={`Locale: ${
        selectedOption.label?.replace('Locale: ', '') || ''
      }`}
    />
  );
};
