import {
  RegionalNameInformation,
  EntityInformation,
  ServiceEntity,
  SharedTextEntity,
  RegionProfiling,
} from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
import { Link } from '@amzn/awsui-components-react';
import { ReactNode } from 'react';

// This is still bad and needs refactoring later, but it should fix the build for now
export const makeEntityOrDraftCell = (
  entity: EntityInformation,
  column: string
): ReactNode => {
  // Handle standard entity properties
  if (column === 'entityId') {
    if ('sharedText' in entity && entity.sharedText) {
      return (
        <Link
          href={`#/Entities/shared-text/${encodeURIComponent(
            entity.sharedText.entityId
          )}`}
        >
          {entity.sharedText.entityId}
        </Link>
      );
    } else if ('service' in entity && entity.service) {
      return (
        <Link
          href={`#/Entities/service/${encodeURIComponent(
            entity.service.entityId
          )}`}
        >
          {entity.service.entityId}
        </Link>
      );
    }
  }

  if (column === 'locale') {
    if ('sharedText' in entity && entity.sharedText) {
      return entity.sharedText.locale;
    } else if ('service' in entity && entity.service) {
      return entity.service.locale;
    }
  }

  // Handle region values
  if ('sharedText' in entity && entity.sharedText) {
    const value = entity.sharedText.value;
    const regionKey = column as RegionProfiling;
    const regionalValue = value[regionKey];
    return (
      regionalValue?.value.map((locString) => locString?.value).join(', ') || ''
    );
  } else if ('service' in entity && entity.service) {
    const value = entity.service.value;
    const regionKey = column as RegionProfiling;
    const regionalValue = value[regionKey];
    return (
      regionalValue?.value.map((locString) => locString?.value).join(', ') || ''
    );
  }

  return '';
};
