import { Route, Switch, Redirect } from 'react-router-dom';
import { AuthSpinner, useAuthState } from '../authentication';
import Home from '../Pages/Home';
import ServiceList from '../Pages/servicePackageInfo/ServiceList';
import PackageDetail from '../Pages/servicePackageInfo/PackageDetail';
import Error from '../Pages/Error';
import { isDevDesk } from '../Pages/servicePackageInfo/utils';
import { useLocation } from 'react-router';
import Moniker from '../moniker/Moniker';
import { Entities } from '../entities/Entities';
import { ReactElement } from 'react';

export default function AppRouter(): ReactElement {
  const { isAuthenticated } = useAuthState();

  const packagePathRegEx = new RegExp('/services/.+/(?!.?(edit)).+/*.*');
  const location = useLocation();
  const isPackagePath = packagePathRegEx.test(location.pathname);

  // Temporarily bypass Cognito auth for dev-desk urls for demo purposes
  if (isAuthenticated || isDevDesk()) {
    //
    return (
      <Switch>
        <Route exact path='/'>
          <Home />
        </Route>
        {/* Paths matching /services/[serviceId]/[packageName] should return <PackageDetail /> */}
        {isPackagePath && (
          <Route path='/services/:serviceId/:packageId'>
            <PackageDetail />
          </Route>
        )}
        {/* Paths matching /services/[serviceId]/edit should return <ServiceList /> */}
        <Route path='/services'>
          <ServiceList />
          {/* Subroute for /services/:serviceId and /services/:serviceId/edit are defined in ServiceList component */}
        </Route>
        <Route path='/error'>
          <Error />
        </Route>
        <Route path='/moniker'>
          <Moniker />
        </Route>
        <Route path='/entities'>
          <Entities />
        </Route>
        <Route>
          <Redirect to='/error' />
        </Route>
      </Switch>
    );
  } else {
    return <AuthSpinner />;
  }
}
