import { FormField, Input } from '@amzn/awsui-components-react';
import { ReactElement, useEffect, useState } from 'react';
import { FieldProps } from '../FieldProps';
import { useAuthState } from '../../../authentication';
import {
  ListEntitiesCommand,
  ListEntitiesCommandOutput,
  EntityInformation,
} from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
import { callApi } from '../../../client';

export const LongNameEntityIdField = (
  props: FieldProps<string, true>
): ReactElement => {
  const [valid, setValid] = useState(true);
  const [entities, setEntities] = useState<EntityInformation[]>([]);

  const entityIds = entities
    .map((entityInfo) => entityInfo.service?.entityId)
    .filter((entityId) => entityId !== undefined && entityId !== props.old);

  const isDuplicate = entityIds.includes(props.value);
  useEffect(
    () =>
      setValid(
        !isDuplicate &&
          (/^[a-zA-Z0-9_-]{1,255}$/.test(props.value) ||
            !(props.required || props.value))
      ),
    [props.value, props.required, isDuplicate]
  );
  useEffect(() => props.onValidate?.(valid), [valid]);
  const { token } = useAuthState();
  useEffect(() => {
    if (!token) return;
    const listEntities = async () => {
      try {
        const response: ListEntitiesCommandOutput = await callApi(
          new ListEntitiesCommand({
            locale: 'ENGLISH',
            entityType: 'SERVICE_ENTITY',
            maxResults: 10000,
          }),
          token
        );
        setEntities(response.entities);
      } catch (error) {
        console.error('Error fetching entitites:', error);
      }
    };

    listEntities();
  }, []);
  return (
    <FormField
      label='Long name entity ID'
      errorText={!valid && isDuplicate ? 'Entity ID already exists' : undefined}
    >
      {typeof props.old === 'string' && props.old !== props.value && (
        <Input disabled={true} value={props.old} />
      )}
      <Input
        disabled={props.disabled}
        value={props.value}
        invalid={!valid}
        onChange={({ detail: { value } }) => {
          props.onChange?.(value);
        }}
        placeholder='e.g. VPClong'
      />
    </FormField>
  );
};
