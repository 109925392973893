import {
  ServiceEntity,
  SharedTextEntity,
} from '@amzn/awsdev-docs-virtual-smiley-typescript-client';

export const fieldTitles: Record<string, string> = {
  entityId: 'Entity ID',
  locale: 'Locale',
  value: 'Value',
  ALL: 'All Regions',
  UNIVERSAL: 'Universal',
  ALL_EXCEPT_BJS: 'All Regions Except BJS',
};
