import {
  Box,
  Button,
  Container,
  FormField,
  Grid,
  Header,
  Select,
  SpaceBetween,
  TextContent,
  StatusIndicator,
  BoxProps,
} from '@amzn/awsui-components-react';
import { OwnerRole, OwnerWithKey } from '../../../interfaces';
import { getOwnerKey } from './utils';
import { Dispatch, ReactElement, useEffect, useState } from 'react';
import OwnershipFields, { getRoleOptions } from '../ui/OwnershipFields';
import { AliasSuggester } from '../../../components/AliasSuggester';

let pocExist = false;
const defaultPlaceholder =
  'Enter full name, alias, or mailing list. Mailing list must end with @amazon.com';
const pocExistPlaceholder = 'Remove the current point of contact to add new';
const placeholder = '';

let removeServicePocFlag: BoxProps.Display = 'none';

const EditOwners = ({
  isThisServicePage,
  isInheritedPOC,
  writers,
  setWriters,
  editors,
  setEditors,
  writerManager,
  setWriterManager,
  pointOfContact,
  setPointOfContact,
}: {
  isThisServicePage: boolean;
  isInheritedPOC: boolean;
  writers?: string[];
  setWriters: Dispatch<string[]>;
  editors: string[] | undefined;
  setEditors?: Dispatch<string[]>;
  writerManager: string[];
  setWriterManager: Dispatch<string[]>;
  pointOfContact?: string;
  setPointOfContact?: Dispatch<string>;
}): ReactElement => {
  const [owners, setOwners] = useState<OwnerWithKey>({});
  const [ownerErrorText, setOwnerErrorText] = useState('');
  const [ownerToAddRole, setOwnerToAddRole] = useState<OwnerRole>();
  const [ownerToAddEmail, setOwnerToAddEmail] = useState<string>();
  const [disablePointOfContactOption, setDisablePointOfContactOption] =
    useState<boolean>(false);

  const isOverriding = !isInheritedPOC;

  const roleOptions = getRoleOptions(isThisServicePage, pocExist);

  const ownerFields = Object.entries(owners).map(([ownerKey, owner]) => {
    return (
      <OwnershipFields
        role={owner.role}
        email={owner.email}
        ownerKey={ownerKey}
        owners={owners}
        updateOwners={setOwners}
        key={ownerKey}
        isServicePage={isThisServicePage}
        isInheritedPointOfContact={isOverriding}
      />
    );
  });

  // Initialize owner state object with owners from props
  useEffect(() => {
    const owners: OwnerWithKey = {};
    if (editors) {
      editors.forEach((editor) => {
        owners[getOwnerKey(editor, 'editors')] = {
          role: 'editors',
          email: editor,
        };
      });
    }
    if (writers) {
      writers.forEach((writer) => {
        owners[getOwnerKey(writer, 'writers')] = {
          role: 'writers',
          email: writer,
        };
      });
    }

    if (writerManager) {
      writerManager.forEach((writerManager) => {
        owners[getOwnerKey(writerManager, 'writerManager')] = {
          role: 'writerManager',
          email: writerManager,
        };
      });
    }

    if (pointOfContact) {
      owners[getOwnerKey(pointOfContact, 'pointOfContact')] = {
        role: 'pointOfContact',
        email: pointOfContact,
      };
    }
    console.log('uh oh', owners, 'isnt', [
      writers,
      editors,
      writerManager,
      pointOfContact,
    ]);
    setOwners(owners);
  }, [writers, editors, writerManager, pointOfContact]);

  // Update owner lists in parent component as owners are added/removed
  useEffect(() => {
    if (setEditors) {
      setEditors(
        Object.values(owners)
          .filter((owner) => owner.role === ('editors' as OwnerRole))
          .map((editor) => editor.email)
      );
    }

    setWriters(
      Object.values(owners)
        .filter((owner) => owner.role === ('writers' as OwnerRole))
        .map((writer) => writer.email)
    );

    setWriterManager(
      Object.values(owners)
        .filter((owner) => owner.role === ('writerManager' as OwnerRole))
        .map((writerManager) => writerManager.email)
    );

    if (setPointOfContact) {
      const pointOfContact = Object.values(owners).filter(
        (owner) => owner.role === ('pointOfContact' as OwnerRole)
      );

      setPointOfContact(pointOfContact[0]?.email || '');

      pocExist = pointOfContact.length > 0;
    }
  }, [owners]);

  // Update input error message
  useEffect(() => {
    if (ownerToAddEmail && ownerToAddRole) {
      const ownerMatches = Object.values(owners).filter((owner) => {
        return owner.email === ownerToAddEmail && owner.role === ownerToAddRole;
      });
      const allLetters = /^[A-Za-z @\-.0-9]+$/;
      const [, alias = ''] = ownerToAddEmail.split('@');
      if (!allLetters.exec(ownerToAddEmail)) {
        setOwnerErrorText('Can only be letters');
      } else if (alias.includes(' ')) {
        setOwnerErrorText('Alias cannot have spaces');
      } else if (ownerMatches.length > 0) {
        setOwnerErrorText('New owner duplicates an existing owner');
      } else {
        setOwnerErrorText('');
      }
    }
  }, [owners, ownerToAddEmail, ownerToAddRole]);

  useEffect(() => {
    if (Object.values(owners).length > 0) {
      const pointOfContacts = Object.values(owners).filter(
        (owner) => owner.role === roleOptions.pointOfContact?.value
      );
      setDisablePointOfContactOption(
        pointOfContacts.length > 0 && !isThisServicePage
      );

      //logic to check if the new point of contact is the same as in service
      if (!isThisServicePage) {
        //indicator to display warning msg when customer trying to delete service poc on package page
        removeServicePocFlag =
          isInheritedPOC && pointOfContacts.length === 0 ? 'block' : 'none';
      }
    } else {
      setDisablePointOfContactOption(false);
      removeServicePocFlag =
        !isThisServicePage &&
        pointOfContact !== undefined &&
        pointOfContact !== '' &&
        isInheritedPOC
          ? 'block'
          : 'none';
    }
  }, [owners]);

  return (
    <Container
      header={
        <Header
          variant='h2'
          description={
            'A custom point of contact can be set in addition to the standard ownership roles to capture things such as service team email alias or contact.'
          }
        >
          Ownership ({Object.keys(owners).length})
        </Header>
      }
    >
      <SpaceBetween direction='vertical' size='xxl'>
        {ownerFields.length > 0 && (
          <Box>
            <Grid
              disableGutters
              gridDefinition={[{ colspan: 4 }, { colspan: 5 }]}
            >
              <TextContent>
                <p>Role </p>
              </TextContent>
              <TextContent>
                <p>Alias or mailing list</p>
              </TextContent>
            </Grid>
            <SpaceBetween direction='vertical' size='xs'>
              {ownerFields}
            </SpaceBetween>
          </Box>
        )}
        <Box margin={{ top: 'xxxs' }} display={removeServicePocFlag}>
          <StatusIndicator type='error'>
            This package lacks a specific point of contact, The contact you're
            removing is associated with the service lvl.
          </StatusIndicator>
        </Box>
        <Box>
          <TextContent>
            <p>Add a new owner</p>
          </TextContent>
          <SpaceBetween direction='vertical' size='xs'>
            <Grid disableGutters gridDefinition={[{ colspan: 9 }]}>
              <Box>
                <Select
                  options={Object.values(roleOptions)}
                  selectedOption={
                    ownerToAddRole ? roleOptions[ownerToAddRole] || null : null
                  }
                  placeholder='Select owner role'
                  onChange={({ detail }) =>
                    setOwnerToAddRole(detail.selectedOption.value as OwnerRole)
                  }
                />
                <FormField errorText={ownerErrorText}>
                  <AliasSuggester
                    ariaLabel='Autosuggest for owner to add to service'
                    aliasToAdd={ownerToAddEmail}
                    setAliasToAdd={setOwnerToAddEmail}
                  />
                </FormField>
              </Box>
            </Grid>
            <Button
              disabled={
                !(ownerToAddRole === 'serviceTeam') &&
                (disablePointOfContactOption ||
                  ownerToAddRole === undefined ||
                  ownerToAddEmail === '' ||
                  ownerToAddEmail === undefined ||
                  ownerErrorText !== '')
              }
              onClick={() => {
                if (ownerToAddRole && ownerToAddEmail) {
                  const newOwnerKey = getOwnerKey(
                    ownerToAddEmail,
                    ownerToAddRole
                  );
                  setOwners({
                    ...owners,
                    [newOwnerKey]: {
                      role: ownerToAddRole,
                      email: ownerToAddEmail,
                    },
                  });
                } else if (ownerToAddRole === 'serviceTeam') {
                  const newOwnerKey = getOwnerKey(
                    'Service team',
                    ownerToAddRole
                  );
                  setOwners({
                    ...owners,
                    [newOwnerKey]: {
                      role: 'pointOfContact',
                      email: 'Service team',
                    },
                  });
                }
                setOwnerToAddEmail('');
                setOwnerToAddRole(undefined);
              }}
            >
              Add owner
            </Button>
          </SpaceBetween>
        </Box>
      </SpaceBetween>
    </Container>
  );
};

export default EditOwners;
