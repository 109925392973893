import {
  Header,
  Table,
  TableProps,
  TextFilter,
} from '@amzn/awsui-components-react/polaris';
import { makeEntityOrDraftCell } from './makeEntityOrDraftCell';
import { fieldTitles } from './f2/fieldTitles';
import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Prefs,
  EntityListCollectionPreferences,
} from './EntityListCollectionPreferences';
import { entityPropList } from './RowPropTypes';
import { Pagination } from '@amzn/awsui-components-react';
import { useAuthState } from '../authentication';
import {
  ListEntitiesCommand,
  EntityInformation,
  EntityType,
  Locale,
  ServiceEntity,
  SharedTextEntity,
  ListEntitiesResponse,
  RegionProfiling,
} from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
import { callApi } from '../client';

import { ExportEntityList } from './ExportEntityList';
import { LocaleSelector } from './LocaleSelector';

export const EntityList = (): ReactElement => {
  const { token } = useAuthState();
  const [sortingDescending, setSortingDescending] = useState(false);
  const location = useLocation();

  const [entityType, setEntityType] = useState<EntityType>(() =>
    location.pathname.includes('/shared-text')
      ? EntityType.SHARED_TEXT
      : EntityType.SERVICE_ENTITY
  );

  useEffect(() => {
    const newEntityType = location.pathname.includes('/shared-text')
      ? EntityType.SHARED_TEXT
      : EntityType.SERVICE_ENTITY;

    if (newEntityType !== entityType) {
      setEntityType(newEntityType);
      setCurrentPage(1);
    }
  }, [location.pathname]);

  const storageKeys = {
    PAGE_NUMBER_KEY: `entity_list_page_number_${entityType}`,
    SEARCH_STRING_KEY: `entity_list_search_string_${entityType}`,
  };

  const COLUMN_PREFERENCES_STORAGE_KEY = 'visible_columns';

  const [serviceLocale, setServiceLocale] = useState<Locale>(() => {
    const savedLocale = localStorage.getItem('serviceLocale');
    return savedLocale && savedLocale in Locale
      ? (savedLocale as Locale)
      : Locale.ENGLISH;
  });

  const [sharedTextLocale, setSharedTextLocale] = useState<Locale>(() => {
    const savedLocale = localStorage.getItem('sharedTextLocale');
    return savedLocale && savedLocale in Locale
      ? (savedLocale as Locale)
      : Locale.ENGLISH;
  });

  const isSharedText = location.pathname.includes('/shared-text');
  const currentLocale = isSharedText ? sharedTextLocale : serviceLocale;

  const handleLocaleChange = (newLocale: Locale) => {
    if (isSharedText) {
      setSharedTextLocale(newLocale);
      localStorage.setItem('sharedTextLocale', newLocale);
    } else {
      setServiceLocale(newLocale);
      localStorage.setItem('serviceLocale', newLocale);
    }

    const entityType = isSharedText
      ? EntityType.SHARED_TEXT
      : EntityType.SERVICE_ENTITY;
    invalidateCache(entityType);
  };

  const getHeaderText = () => {
    return entityType === EntityType.SHARED_TEXT
      ? 'Shared Text Entities'
      : 'Service Entities';
  };

  const [sortColumn, setSortColumn] = useState<
    TableProps.SortingColumn<EntityInformation>
  >({
    sortingField: 'entityId',
  });

  const [rows, setRows] = useState<EntityInformation[]>([]);
  const [filteredRows, setFilteredRows] = useState<EntityInformation[]>([]);

  const getAllRegions = (entities: EntityInformation[]): string[] => {
    const regionsSet = new Set<string>();

    entities.forEach((entity) => {
      if ('sharedText' in entity && entity.sharedText) {
        Object.keys(entity.sharedText.value).forEach((region) =>
          regionsSet.add(region)
        );
      } else if ('service' in entity && entity.service) {
        Object.keys(entity.service.value).forEach((region) =>
          regionsSet.add(region)
        );
      }
    });

    const regions = Array.from(regionsSet);

    return [
      ...(regions.includes('ALL') ? ['ALL'] : []),
      ...(regions.includes('UNIVERSAL') ? ['UNIVERSAL'] : []),
      ...regions
        .filter((region) => region !== 'ALL' && region !== 'UNIVERSAL')
        .sort(),
    ];
  };

  const orderRows = () => {
    const field = sortColumn.sortingField || 'entityId'; // access the sortingField property

    return [...filteredRows].sort((a, b) => {
      const valueA = getFieldValue(a, field);
      const valueB = getFieldValue(b, field);

      return sortingDescending
        ? String(valueB).localeCompare(String(valueA))
        : String(valueA).localeCompare(String(valueB));
    });
  };

  const getFieldValue = (entity: EntityInformation, field: string): string => {
    if (field === 'entityId') {
      return (
        ('sharedText' in entity
          ? entity.sharedText?.entityId
          : entity.service?.entityId) || ''
      );
    }

    if (field === 'locale') {
      return (
        ('sharedText' in entity
          ? entity.sharedText?.locale
          : entity.service?.locale) || ''
      );
    }

    if ('sharedText' in entity && entity.sharedText) {
      const value = entity.sharedText.value;
      const regionKey = field as RegionProfiling;
      const regionalValue = value[regionKey];
      return (
        regionalValue?.value.map((locString) => locString?.value).join(', ') ||
        ''
      );
    } else if ('service' in entity && entity.service) {
      const value = entity.service.value;
      const regionKey = field as RegionProfiling;
      const regionalValue = value[regionKey];
      return (
        regionalValue?.value.map((locString) => locString?.value).join(', ') ||
        ''
      );
    }

    return '';
  };

  const [orderedRows, setOrderedRows] = useState(orderRows());
  const [filteringText, setFilteringText] = useState(() => {
    return localStorage.getItem(storageKeys.SEARCH_STRING_KEY) || '';
  });

  const getPage = (page: number, size: number) =>
    orderedRows.slice((page - 1) * size, page * size);

  const PAGE_SIZE_STORAGE_KEY = 'entityPageSize';

  useEffect(() => {
    const savedPageSize = localStorage.getItem(PAGE_SIZE_STORAGE_KEY);
    if (savedPageSize) {
      const pageSize = parseInt(savedPageSize);
      setPreferences((prev) => ({ ...prev, pageSize }));
    }
  }, []);

  const [currentPage, setCurrentPage] = useState(() => {
    const savedPage = localStorage.getItem(storageKeys.PAGE_NUMBER_KEY);
    console.debug('Loading saved page:', savedPage, 'for type:', entityType);
    return savedPage ? parseInt(savedPage) : 1;
  });

  useEffect(() => {
    console.debug('Saving page:', currentPage, 'for type:', entityType);
    localStorage.setItem(storageKeys.PAGE_NUMBER_KEY, currentPage.toString());
  }, [currentPage, entityType]);

  useEffect(() => {
    localStorage.setItem(storageKeys.SEARCH_STRING_KEY, filteringText);
  }, [filteringText, storageKeys.SEARCH_STRING_KEY]);

  const CACHE_KEYS = {
    [EntityType.SERVICE_ENTITY]: 'service_entities_cache',
    [EntityType.SHARED_TEXT]: 'shared_text_entities_cache',
  };

  const CACHE_EXPIRY_KEYS = {
    [EntityType.SERVICE_ENTITY]: 'service_entities_cache_expiry',
    [EntityType.SHARED_TEXT]: 'shared_text_entities_cache_expiry',
  };

  const invalidateCache = (type?: EntityType) => {
    if (type) {
      localStorage.removeItem(CACHE_KEYS[type]);
      localStorage.removeItem(CACHE_EXPIRY_KEYS[type]);
    } else {
      Object.values(CACHE_KEYS).forEach((key) => localStorage.removeItem(key));
      Object.values(CACHE_EXPIRY_KEYS).forEach((key) =>
        localStorage.removeItem(key)
      );
    }
  };

  const [allEntities, setAllEntities] = useState<EntityInformation[]>([]);
  const regions = useMemo(() => getAllRegions(allEntities), [allEntities]);

  const initialVisibleColumns = useMemo(
    () => ['entityId', 'ALL', 'UNIVERSAL'],
    [regions]
  );

  const ALL_COLUMNS: string[] = ['entityId', 'locale', ...regions];

  const [VISIBLE_COLUMNS, setVISIBLE_COLUMNS] = useState(initialVisibleColumns);

  const getCachedColumnPreferences = (): readonly string[] | null => {
    const cachedColumns = localStorage.getItem(COLUMN_PREFERENCES_STORAGE_KEY);
    const parsedColumns = cachedColumns ? JSON.parse(cachedColumns) : null;
    if (!parsedColumns) return VISIBLE_COLUMNS;

    const validColumns = parsedColumns.filter((column: string) =>
      ALL_COLUMNS.includes(column)
    );
    return validColumns.length > 1 ? validColumns : VISIBLE_COLUMNS;
  };

  const [preferences, setPreferences] = useState<Prefs>(() => {
    const cachedColumns = getCachedColumnPreferences();
    return {
      pageSize: 20,
      wrapLines: true,
      visibleContent: cachedColumns || VISIBLE_COLUMNS,
    };
  });

  useEffect(() => {
    setPreferences((prev) => ({
      ...prev,
      visibleContent: VISIBLE_COLUMNS,
    }));
    setVISIBLE_COLUMNS(VISIBLE_COLUMNS);
  }, [regions, VISIBLE_COLUMNS]);

  const saveColumnPreferences = (columns: readonly string[]) => {
    localStorage.setItem(
      COLUMN_PREFERENCES_STORAGE_KEY,
      JSON.stringify(Array.from(columns))
    );
  };
  useEffect(() => {
    if (preferences.visibleContent) {
      saveColumnPreferences(preferences.visibleContent);
    }
  }, [preferences.visibleContent, entityType]);

  useEffect(() => {
    const cachedColumns = getCachedColumnPreferences();
    if (cachedColumns) {
      setPreferences((prev) => ({
        ...prev,
        visibleContent: Array.from(cachedColumns),
      }));
    } else {
      setPreferences((prev) => ({
        ...prev,
        visibleContent: Array.from(VISIBLE_COLUMNS),
      }));
    }
  }, [entityType, VISIBLE_COLUMNS]);

  useEffect(() => {
    if (preferences.pageSize) {
      localStorage.setItem(
        PAGE_SIZE_STORAGE_KEY,
        preferences.pageSize.toString()
      );
    }
  }, [preferences.pageSize]);

  useEffect(() => {
    if (!token) return console.warn('Token not present');

    const entityType = location.pathname.includes('/shared-text')
      ? EntityType.SHARED_TEXT
      : EntityType.SERVICE_ENTITY;

    const CACHE_DURATION = 60 * 60 * 1000;

    const getCachedData = (type: EntityType) => {
      const cachedData = localStorage.getItem(CACHE_KEYS[type]);
      const expiryTime = localStorage.getItem(CACHE_EXPIRY_KEYS[type]);

      if (cachedData && expiryTime) {
        if (Number(expiryTime) > Date.now()) {
          return JSON.parse(cachedData) as EntityInformation[];
        }
        localStorage.removeItem(CACHE_KEYS[type]);
        localStorage.removeItem(CACHE_EXPIRY_KEYS[type]);
      }
      return null;
    };

    const setCacheData = (type: EntityType, data: EntityInformation[]) => {
      localStorage.setItem(CACHE_KEYS[type], JSON.stringify(data));
      localStorage.setItem(
        CACHE_EXPIRY_KEYS[type],
        (Date.now() + CACHE_DURATION).toString()
      );
    };

    const fetchAllEntities = async () => {
      console.log(
        `Fetching entities of type: ${entityType} and locale: ${currentLocale}`
      );
      const cachedEntities = getCachedData(entityType);
      if (cachedEntities) {
        setAllEntities(cachedEntities);
        setRows(cachedEntities);
        return;
      }

      let fetchedEntities: EntityInformation[] = [];
      let nextToken: string | undefined = undefined;

      do {
        try {
          const response: ListEntitiesResponse = await callApi(
            new ListEntitiesCommand({
              locale: currentLocale,
              entityType: entityType,
              nextToken: nextToken,
            }),
            token
          );

          fetchedEntities = [...fetchedEntities, ...(response.entities || [])];
          nextToken = response.nextToken;
        } catch (e) {
          console.error(e);
          setRows([]);
          setAllEntities([]);
          return;
        }
      } while (nextToken);

      console.log(`Received all ${entityType} entities:`, fetchedEntities);
      setAllEntities(fetchedEntities);
      setRows(fetchedEntities);
      setCacheData(entityType, fetchedEntities);
      setVISIBLE_COLUMNS(preferences.visibleContent as string[]);
    };

    fetchAllEntities();
  }, [token, location.pathname, currentLocale]);

  useEffect(() => {
    setFilteredRows(
      rows.filter((entity: EntityInformation) => {
        const entityId =
          'sharedText' in entity && entity.sharedText
            ? entity.sharedText.entityId
            : 'service' in entity && entity.service
            ? entity.service.entityId
            : '';

        return entityId.toLowerCase().includes(filteringText.toLowerCase());
      })
    );
    const savedPage = localStorage.getItem(storageKeys.PAGE_NUMBER_KEY);
    setCurrentPage(savedPage ? parseInt(savedPage) : 1);
  }, [rows, filteringText, location.pathname, currentLocale]);

  const previousEntityTypeRef = useRef(entityType);

  useEffect(() => {
    if (previousEntityTypeRef.current !== entityType) {
      setCurrentPage(1);
      previousEntityTypeRef.current = entityType;
    }
  }, [entityType]);

  const countPages = () =>
    Math.ceil(filteredRows.length / (preferences.pageSize ?? 20));

  const [totalPages, setTotalPages] = useState(countPages());

  const [items, setItems] = useState<EntityInformation[]>([]);

  useEffect(() => {
    setItems(getPage(currentPage, preferences.pageSize ?? 20));
  }, [preferences.pageSize, currentPage, orderedRows, totalPages]);

  useEffect(() => {
    setTotalPages(countPages());
  }, [preferences.pageSize, filteredRows]);

  useEffect(
    () => setOrderedRows(orderRows()),
    [filteredRows, sortingDescending, sortColumn]
  );

  useEffect(() => {
    if (
      preferences.visibleContent &&
      JSON.stringify(preferences.visibleContent) !==
        JSON.stringify(VISIBLE_COLUMNS)
    ) {
      setVISIBLE_COLUMNS([...preferences.visibleContent]);
    }
  }, [preferences.visibleContent]);

  return (
    <>
      <Table
        stickyColumns={{ first: 1, last: 0 }}
        variant='container'
        contentDensity='comfortable'
        filter={
          <TextFilter
            filteringPlaceholder='Find an entity'
            filteringText={filteringText}
            onChange={(e) => {
              setFilteringText(e.detail.filteringText);
              setCurrentPage(1);
            }}
          />
        }
        sortingColumn={sortColumn}
        onSortingChange={(e) => {
          setSortColumn(e.detail.sortingColumn);
          setSortingDescending(e.detail.isDescending ?? true);
        }}
        header={
          <Header
            counter={`(${filteredRows.length})`}
            actions={
              <div
                style={{ display: 'flex', gap: '10px', alignItems: 'center' }}
              >
                <LocaleSelector
                  currentLocale={currentLocale}
                  onLocaleChange={handleLocaleChange}
                />
                <ExportEntityList rows={allEntities} columns={ALL_COLUMNS} />
              </div>
            }
          >
            {getHeaderText()}
          </Header>
        }
        sortingDescending={sortingDescending}
        columnDefinitions={VISIBLE_COLUMNS.map(
          (column): TableProps.ColumnDefinition<EntityInformation> => ({
            id: column,
            header: fieldTitles[column as keyof typeof fieldTitles] || column,
            sortingField: column,
            isRowHeader: column === 'entityId',
            cell: (entity: EntityInformation) =>
              makeEntityOrDraftCell(entity, column),
          })
        )}
        items={items}
        preferences={
          <EntityListCollectionPreferences
            fields={ALL_COLUMNS}
            preferences={preferences}
            setPreferences={setPreferences}
          />
        }
        pagination={
          <Pagination
            onPreviousPageClick={() => setCurrentPage(currentPage - 1)}
            currentPageIndex={currentPage}
            onNextPageClick={() => setCurrentPage(currentPage + 1)}
            pagesCount={totalPages}
            onChange={(e) => setCurrentPage(e.detail.currentPageIndex)}
          />
        }
      />
    </>
  );
};
